export default {
  data () {
    return {
      visible: false
    }
  },
  methods: {
    onCloseDialog (whichDialogVisible = 'visible') {
      this[whichDialogVisible] = false
    }
  }
}
