<template>
  <div class="header" :style="{ color, backgroundColor: bgColor }">
    <div v-if="showBackButton" @click="goBack" class="back-btn">
      <img src="@/assets/img/header-back-btn.png" />
    </div>
    <div v-if="showCustomerService" class="left-block" @click="showCustomerServiceModal">
      <img src="@/assets/img/header-left-icon.png" />
    </div>
    {{ title }}
    <van-dialog v-model="visible" :showConfirmButton="false" close-on-click-overlay>
      <template #title>
        <div class="customer-service-title">
          <p>Customer Service</p>
          <div @click="onCloseDialog()" class="close-icon">
            <img src="@/assets/img/close.png" alt="关闭">
          </div>
        </div>
      </template>
      <div class="customer-service-body">
        <div class="line-block">
          <p class="text">Email Customer Service</p>
          <a class="text" href="mailto:customer@danadana.xyz">customer@danadana.xyz</a>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'

export default {
  name: 'BaseHeaderBar',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  props: {
    backType: {
      type: String,
      default: '1'
    },
    title: {
      type: String,
      default: 'Andalan Sejahtera'
    },
    showCustomerService: {
      type: Boolean,
      default: false
    },
    showBackButton: {
      type: Boolean,
      default: true
    },
    color: {
      type: String
    },
    bgColor: {
      type: String
    }
  },
  methods: {
    goBack () {
      if (this.backType === '2') {
        history.back()
      } else {
        this.$router.back()
      }
    },
    showCustomerServiceModal () {
      this.visible = true
    }
  }
}
</script>

<style scoped lang="less">
.header {
  position: relative;
  width: 100%;
  height: 88px;
  line-height: 88px;
  font-size: 30px;
  color: #1C0A1B;
  text-align: center;
  z-index: 3;
}

.back-btn {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 30px;
  cursor: pointer;

  img {
    width: 24px;
    height: 40px;
  }
}

.left-block {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  img {
    width: 59px;
    height: 55px;
  }
}

.customer-service-title {
  position: relative;

  p {
    .dialog-title;
  }

  .close-icon {
    position: absolute;
    top: 50%;
    right: 32px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.customer-service-body {
  padding: 32px 25px 54px;

  .line-block {
    display: flex;
    justify-content: space-between;
    height: 88px;
    line-height: 88px;

    .text {
      font-size: 26px;
      color: #333;
    }

    &:nth-child(n+2) {
      margin-top: 6px;
    }

    &:nth-child(even) {
      background-color: #F6F4F4;
    }
  }
}
</style>
