export default [
  {
    path: '/index',
    redirect: '/',
    hidden: true
  },
  {
    path: '/home',
    redirect: '/',
    hidden: true
  }
]
