export default {
  /**
   * *payload
   * type {string} 要设置的state类型
   * value 要设置的值
   */
  recordInfo (state, payload) {
    const type = payload.type
    state[type] = payload.value
  }
}
