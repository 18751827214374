<template>
  <div id="app">
    <router-view/>
    <van-loading v-show="showLoading" color="#E92326" vertical>Memuat...</van-loading>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { Loading } from 'vant'

Vue.use(Loading)

export default {
  computed: mapState(['showLoading'])
}
</script>

<style lang="less">
#app {
  font-family: PingFangSC, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
